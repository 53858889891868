import { FC, ReactNode } from 'react';

import {
	ArrowWrapper,
	DotsWrapper,
	SliderWrapper,
	StyledDot,
	StyledIcon,
	StyledList,
	StyledSlider,
} from './LegsInfoCarouselStyle';

interface ArrowProps {
	className?: string;
	prev?: boolean;
	onClick?: () => void;
}

const Arrow: FC<ArrowProps> = ({ onClick, className, prev }) => (
	<ArrowWrapper onClick={onClick} className={className} prev={prev}>
		<StyledIcon type="dropdown-blue" prev={prev} />
	</ArrowWrapper>
);

interface LegsInfoCarouselProps {
	children: ReactNode;
	setCurrentIndex: (current: number) => void;
	fixedWidth?: number;
}

export const LegsInfoCarousel: FC<LegsInfoCarouselProps> = ({
	children,
	setCurrentIndex,
	fixedWidth,
}) => {
	const settings = {
		dots: true,
		appendDots: (dots) => (
			<DotsWrapper>
				<StyledList> {dots} </StyledList>
			</DotsWrapper>
		),
		customPaging: (i) => <StyledDot key={i + 1} />,
		infinite: false,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		nextArrow: <Arrow />,
		prevArrow: <Arrow prev />,
		className: 'itenary',
		variableWidth: fixedWidth !== undefined,
		responsive: [
			{
				breakpoint: 1000,
				settings: {
					slidesToShow: 1,
				},
			},
			{
				breakpoint: 700,
				settings: {
					slidesToShow: 1,
				},
			},

			{
				breakpoint: 450,
				settings: {
					slidesToShow: 1,
					variableWidth: false,
					arrows: false,
				},
			},
		],
		afterChange: (current) => setCurrentIndex(current),
	};

	return (
		<SliderWrapper>
			<StyledSlider {...settings}>{children}</StyledSlider>
		</SliderWrapper>
	);
};
