import styled from '@emotion/styled';
import Slider from 'react-slick';

import { Icon } from '../Icon';

export const StyledSlider = styled(Slider)`
	padding-bottom: 25px;
`;

export const StyledIcon = styled(Icon)<{ prev?: boolean }>`
	transform: ${({ prev }) => (prev ? 'rotate(90deg)' : 'rotate(-90deg)')};
`;

export const ArrowWrapper = styled('div')<{ prev?: boolean }>`
	width: 52px;
	height: 52px;
	box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.15);
	border-radius: 50%;
	background: #fff;
	display: none;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	position: absolute;
	right: ${({ prev }) => (prev ? 'auto' : '-145px')};
	left: ${({ prev }) => (prev ? '-145px' : 'auto')};
	top: 45%;
	transform: translateY(-50%);
	z-index: 2;

	&:hover {
		background: #fff;
	}

	:before {
		display: none;
	}

	&.slick-disabled {
		display: none;
	}

	${({ theme }) => theme.breakpoints.up('md')} {
		display: flex;
	}
	${({ theme }) => theme.breakpoints.down('xl')} {
		right: ${({ prev }) => (prev ? 'auto' : '15px')};
		left: ${({ prev }) => (prev ? '15px' : 'auto')};
	}
`;

export const SliderWrapper = styled('div')`
	.itenary .slick-list {
		overflow: visible;
		margin: 0;
	}

	.itenary .slick-track {
		display: flex;
		margin-left: 0;
	}

	.itenary .slick-slider {
		position: static;
	}

	.itenary .slick-dots {
		width: 100%;
	}

	.itenary .slick-dots ul {
		display: flex;
		justify-content: center;
		flex-direction: row;
	}

	.itenary .slick-dots li {
		position: relative;
		width: 12px;
		height: 12px;
		border-radius: 50%;
		border: none;
		cursor: pointer;
		margin: 0 4px;

		:hover {
			background-color: ${({ theme }) => theme.colors.primaryDarkest};
		}

		::marker {
			display: none;
			content: '';
		}
	}
	.itenary .slick-dots li.slick-active {
		display: inline-block;
		background-color: ${({ theme }) => theme.colors.primaryDarkest};
	}

	.itenary .slick-slide {
		margin: 0 17px;
	}

	${({ theme }) => theme.breakpoints.down('mini')} {
		margin-left: -13px;
	}
`;

export const DotsWrapper = styled('div')`
	margin: 0 -20px;

	${({ theme }) => theme.breakpoints.up('md')} {
		width: ${({ theme }) => theme.dimensions.pageWidth};
	}
`;

export const StyledList = styled('ul')`
	margin: 0px;
`;

export const StyledDot = styled('div')`
	width: 12px;
	height: 12px;
	border-radius: 50%;
	border: 1px solid ${({ theme }) => theme.colors.primaryDarkest};
`;
