import { FC, ReactNode } from 'react';

import styled from '@emotion/styled';

const Wrapper = styled('aside')`
	padding: 5px;
	flex-shrink: 0;
	border-bottom: 1px dashed #c0c3c6;
	${({ theme }) => theme.breakpoints.up('sm')} {
		min-height: 277px;
		border-bottom: 0 none;
		border-right: 1px dashed #c0c3c6;
		padding-right: 18px;
		width: 37%;
	}
`;

interface TicketASideProps {
	children: ReactNode;
}

const TicketASide: FC<TicketASideProps> = ({ children }) => <Wrapper>{children}</Wrapper>;

export default TicketASide;
