import { FC } from 'react';

import styled from '@emotion/styled';
import { ModeEditOutline } from '@mui/icons-material';
import { Box, Button, Stack, Typography } from '@mui/material';
import Link from 'next/link';

import { Icon } from '@fllite-fe/shared/src/components/Icon';
import { formatPrice } from '@fllite-fe/shared/src/utils/formatters';

import { BusinessCase, Proposal } from '../../types';
import { translateBusinessCaseStatus } from '../../utils/commonTrans';
import { getBCStatus } from '../../utils/getBCStatus';
import { DaysLeft } from '../LegsInfo/DaysLeft';
import { StatusInfoIcon } from '../LegsInfo/StatusInfoIcon';
import { PriceTaxes } from '../ProposalCard/PriceTaxes';

const MoreLinkIcon = styled(Icon)`
	height: 20px;
	margin-left: 5px;
	display: inline-flex;
`;

const FlightInfo = styled(Box)`
	background: #8cc0ff50;
	border-radius: 4px;
	padding: 8px 15px;
	align-items: center;
	font-size: 14px;
	font-weight: 500;
	letter-spacing: 1px;
	color: white;
	align-self: flex-end;
	white-space: nowrap;
	display: flex;
	letter-spacing: 0;
	font-family: poppins, Roboto, 'Helvetica Neue';
`;

interface Props {
	numberOfSeats: number;
	aircraftName?: string;
	proposal?: Proposal;
	showPrice?: boolean;
	isBlue?: boolean;
	isUpcomingTrip?: boolean;
	businessCase?: BusinessCase;
	inDays?: number;
	hasBookedDesign?: boolean;
	onEditRequestClick?: () => void;
	requestedNumberOfSeats?: number;
}

export const TripInfoCardMobileDetailedPart: FC<Props> = ({
	numberOfSeats,
	aircraftName,
	proposal,
	showPrice = true,
	isBlue,
	isUpcomingTrip,
	businessCase,
	inDays,
	hasBookedDesign,
	onEditRequestClick,
	requestedNumberOfSeats,
}) => {
	const { isParticipant, isWaitingRoom } = businessCase
		? getBCStatus(businessCase)
		: {
				isParticipant: undefined,
				isWaitingRoom: undefined,
			};
	return (
		<>
			{isWaitingRoom && isParticipant ? (
				<Stack direction="row" alignItems="center" spacing={1}>
					<Typography
						fontSize={12}
						fontWeight={800}
						color="#8ace57"
						letterSpacing="2px"
						textTransform="uppercase"
						component="span"
					>
						Passengers
					</Typography>
					<Typography fontSize={12} component="span">
						<Typography component="span" fontSize={14} fontWeight={600} mr="2px">
							{businessCase.numberOfOccupiedSeats}
						</Typography>
						out of {numberOfSeats} {`seat${numberOfSeats > 1 ? 's' : ''}`}
					</Typography>
				</Stack>
			) : (
				<Stack direction="row" alignItems="center" spacing={1}>
					<Typography
						fontSize={12}
						fontWeight={800}
						color="#8ace57"
						letterSpacing="2px"
						textTransform="uppercase"
						component="span"
					>
						Capacity
					</Typography>
					<Typography fontSize={12} component="span">
						<Typography component="span" fontSize={14} fontWeight={600}>
							{numberOfSeats}
						</Typography>{' '}
						{`seat${numberOfSeats > 1 ? 's' : ''}`}
					</Typography>
				</Stack>
			)}

			{aircraftName && (
				<Stack direction="row" alignItems="center" spacing={1}>
					<Typography
						fontSize={12}
						fontWeight={800}
						color="#8ace57"
						letterSpacing="2px"
						textTransform="uppercase"
						component="span"
					>
						Aircraft
					</Typography>
					<Typography fontSize={12} component="span">
						<Typography component="span" fontSize={14} fontWeight={600}>
							{aircraftName}
						</Typography>
					</Typography>
				</Stack>
			)}

			{proposal?.priceInclMargin && showPrice && (
				<Stack alignItems="center" direction="row" spacing={1}>
					<Typography
						fontSize={12}
						fontWeight={800}
						color="#8ace57"
						letterSpacing="2px"
						textTransform="uppercase"
						component="span"
					>
						Trip Full Price
					</Typography>
					<Typography fontSize={12} component="span">
						<Typography
							component="span"
							fontSize={16}
							color={isBlue ? 'white' : 'primary'}
							fontWeight={600}
						>
							{formatPrice(proposal.priceInclMargin)}
						</Typography>{' '}
						<PriceTaxes
							numberOfSeats={requestedNumberOfSeats ?? proposal.numberOfSeats}
							numberOfLegs={proposal.proposalLegs.length}
							price={proposal.priceInclMargin}
							proposalLegs={proposal.proposalLegs}
						/>
					</Typography>
				</Stack>
			)}
			{isUpcomingTrip && (
				<Stack spacing={1} justifyContent="space-between" width="100%">
					<Stack spacing={1} alignItems="flex-start">
						<FlightInfo style={{ width: '100%' }}>
							<StatusInfoIcon
								hasBookedDesign={hasBookedDesign}
								isExpired={businessCase?.status === 'expired'}
							/>
							{translateBusinessCaseStatus(businessCase)}
						</FlightInfo>
						<DaysLeft inDays={inDays} />
					</Stack>
					<Stack alignItems="center">
						<Link href={`/detail/${businessCase.id}`} passHref>
							<Typography color="white" fontWeight={500}>
								See trip details
								<MoreLinkIcon type="arrow-right-white" />
							</Typography>
						</Link>
					</Stack>
				</Stack>
			)}
			{onEditRequestClick && (
				<Box alignSelf="center">
					<Button variant="text" component="div" onClick={onEditRequestClick}>
						<Stack direction="row" spacing={1}>
							<ModeEditOutline color="primary" fontSize="small" />
							<Box>Edit Request</Box>
						</Stack>
					</Button>
				</Box>
			)}
		</>
	);
};
