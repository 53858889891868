import { FC, useMemo } from 'react';

import { MarkerF as GoogleMarker } from '@react-google-maps/api';

import { Point } from './types';

interface MarkerProps {
	point: Point;
}

declare const google: any;

export const Marker: FC<MarkerProps> = ({ point }) => {
	const markerImage = useMemo(
		() => ({
			url: '/assets/citymarker.svg',
			anchor: new google.maps.Point(25, 25),
		}),
		[google],
	);
	return <GoogleMarker position={point} icon={markerImage} cursor="default" />;
};
