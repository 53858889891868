import { FC, ReactNode } from 'react';

import { Element } from 'react-scroll';

interface ScrollElementProps {
	children: ReactNode;
	nameElement: string;
}

export const ScrollElement: FC<ScrollElementProps> = ({ children, nameElement }) => (
	<Element name={nameElement}>{children}</Element>
);
