import { FC, ReactNode } from 'react';

import styled from '@emotion/styled';

const Wrapper = styled('div')<{ inverted?: boolean; isInShade?: boolean; isMultiLeg?: boolean }>`
	background-color: ${({ inverted }) => (inverted ? 'white' : '#004ba6')};
	border-radius: 8px;
	padding: 8px;
	margin-bottom: 24px;
	opacity: ${({ isInShade }) => (isInShade ? '0.4' : '1')};
	width: ${({ theme, isMultiLeg }) => (isMultiLeg ? theme.dimensions.clientPageWidth : '100%')};

	${({ theme }) => theme.breakpoints.up('sm')} {
		background: ${({ inverted }) => `radial-gradient(circle at 37% top, rgba(204, 0, 0, 0) 8px, ${
			inverted ? 'white' : '#004ba6'
		} 9px),
			radial-gradient(circle at 37% bottom, rgba(204, 0, 0, 0) 8px, ${
				inverted ? 'white' : '#2077e0'
			} 9px)`};
		background-position:
			top left,
			bottom left;
		background-size: 100% 50%;
		background-repeat: no-repeat;

		padding: 8px 0;
	}

	@media (max-width: ${({ theme }) => theme.dimensions.clientPageWidth}) {
		width: ${({ isMultiLeg }) => (isMultiLeg ? '95vw' : '100%')};
	}
`;

const Content = styled('div')<{ inverted?: boolean }>`
	background: ${({ inverted }) =>
		inverted ? 'white' : 'linear-gradient(0deg, #2077e0 0%, #004ba6 100%)'};
	width: 100%;
	display: flex;
	flex-direction: column;
	color: white;
	${({ theme }) => theme.breakpoints.up('sm')} {
		flex-direction: row;
	}
`;

const Ticket: FC<{
	inverted?: boolean;
	isInShade?: boolean;
	isMultiLeg?: boolean;
	fixedWidth?: number;
	children: ReactNode;
}> = ({ children, inverted, isInShade, isMultiLeg, fixedWidth }) => (
	<Wrapper
		inverted={inverted}
		isInShade={isInShade}
		isMultiLeg={isMultiLeg}
		style={fixedWidth ? { width: fixedWidth } : {}}
	>
		<Content inverted={inverted}>{children}</Content>
	</Wrapper>
);

export default Ticket;
