import { FC, ReactElement, ReactNode } from 'react';

import styled from '@emotion/styled';

import { H2 } from './Text';

interface ContainerProps {
	isError?: boolean;
	isDashboard?: boolean;
	isFullScreen?: boolean;
}

export const Container = styled('div')<ContainerProps>`
	padding: 0;
	background: white;
	box-shadow: 0px 4px 30px rgba(53, 53, 53, 0.06);
	border-radius: ${({ isFullScreen }) => (isFullScreen ? 0 : '8px')};
	border: 1px solid ${({ isError, theme }) => (isError ? theme.colors.danger : '#dddfe3')};
	${({ isDashboard }) => isDashboard && `border: none;`}
	margin-bottom: 24px;
	${({ isFullScreen }) => isFullScreen && `height: 100%;`}

	${({ theme }) => theme.breakpoints.up('md')} {
		margin-left: 0;
		margin-right: 0;
	}
`;

interface HeaderContainerProps {
	tryHasOneLine?: boolean;
	headerContentIsOnRow?: boolean;
}

export const HeaderContainer = styled('div')<HeaderContainerProps>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px 26px 0;
	text-align: left;

	${({ theme }) => theme.breakpoints.down('sm')} {
		${({ tryHasOneLine }) =>
			!tryHasOneLine &&
			`
			flex-direction: ${({ headerContentIsOnRow }) => (headerContentIsOnRow ? 'row' : 'column')};
			align-items: flex-start;
			`}
		padding:  16px 16px 0;
	}
`;

export const Content = styled('div')<{ isFullScreen?: boolean }>`
	padding: 16px 26px 26px;
	${({ isFullScreen }) => isFullScreen && `height: 100%;`}

	${({ theme }) => theme.breakpoints.down('sm')} {
		padding: 8px 16px 20px;
	}
`;

export const Title = styled(H2)<{ isAdmin?: boolean; isDashboard?: boolean }>`
	font-size: ${({ isAdmin }) => (isAdmin ? '1.5rem' : '20px')};
	line-height: 32px;
	color: ${({ isAdmin, theme, isDashboard }) =>
		isAdmin ? 'rgba(0, 0, 0, 0.87)' : isDashboard ? theme.colors.primary : '#002553'};
	font-weight: ${({ isAdmin }) => (isAdmin ? 400 : 600)};

	${({ theme }) => theme.breakpoints.down('sm')} {
		font-size: 20px;
	}
`;

export interface CardProps {
	title?: ReactNode | string;
	maxWidth?: boolean;
	smallPadding?: boolean;
	headerContent?: ReactElement;
	isError?: boolean;
	children: ReactNode;
	isDashboard?: boolean;
	tryHasHeaderInOneLine?: boolean;
	isFullScreen?: boolean;
}

export const Card: FC<CardProps> = ({
	title,
	children,
	headerContent,
	isError,
	tryHasHeaderInOneLine,
	isDashboard,
	isFullScreen,
}) => (
	<Container isError={isError} isDashboard={isDashboard} isFullScreen={isFullScreen}>
		{title && (
			<>
				<HeaderContainer tryHasOneLine={tryHasHeaderInOneLine}>
					<Title isDashboard={isDashboard}>{title}</Title>
					{headerContent}
				</HeaderContainer>
			</>
		)}

		<Content isFullScreen={isFullScreen}>{children}</Content>
	</Container>
);
