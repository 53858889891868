import { FC, useEffect, useState } from 'react';

import styled from '@emotion/styled';
import { useMediaQuery } from '@mui/material';
import useMeasure from 'react-use-measure';

import { muiTheme } from '@fllite-fe/shared/src/muiTheme';

import { Row } from '../../form-fields/sharedStyles';
import { BusinessCase, InquiryLeg } from '../../types';
import { getBCStatus } from '../../utils/getBCStatus';
import { Leg } from '../RequestAFlight/Map/types';
import { TripInfoCardMobile } from '../TripInfoCard/TripInfoCardMobile';
import { LegsInfoCard } from './LegsInfoCard';
import { LegsInfoCardProps, LegsInfoCardSimple } from './LegsInfoCardSimple';
import { LegsInfoCarousel } from './LegsInfoCarousel';

const Wrapper = styled('div')`
	width: 100%;
	max-width: 1150px;
	margin: 0 auto;
	position: relative;
	z-index: 1;
`;

const FullRow = styled(Row)`
	min-width: 100%;
`;

interface LegsInfoProps {
	businessCase: BusinessCase;
	inverted?: boolean;
	smallTicket?: boolean;
	isUpcomingTrip?: boolean;
	onEditRequestClick?: () => void;
}
const LegsInfoByType: React.FC<
	LegsInfoCardProps & { smallTicket: boolean; mapLegs: Leg[]; aircraftName: string }
> = ({ smallTicket, ...props }) =>
	smallTicket ? <LegsInfoCardSimple {...props} /> : <LegsInfoCard {...props} />;

const LegsInfo: FC<LegsInfoProps> = ({
	businessCase,
	inverted,
	isUpcomingTrip = false,
	smallTicket = false,
	onEditRequestClick,
}) => {
	const { legsResource, aircraftName, numberOfSeats, flightTypeName, isMultiLeg } =
		getBCStatus(businessCase);

	const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);

	const isDesktop = useMediaQuery(muiTheme.breakpoints.up('md'));

	const [sliderWrapperRef, { width: sliderWidth }] = useMeasure();

	const [firstLeg, setFirstLeg] = useState<InquiryLeg>(null);
	const [returnLeg, setReturnLeg] = useState<InquiryLeg>(null);
	const [mapLegs, setMapLegs] = useState<Leg[]>([]);

	useEffect(() => {
		if (!legsResource) return;
		const t = legsResource.reduce((acc, legItem) => {
			return [
				...acc,
				{
					from: {
						lat: legItem.departureAirport.latitude,
						lng: legItem.departureAirport.longitude,
					},
					to: {
						lat: legItem.destinationAirport.latitude,
						lng: legItem.destinationAirport.longitude,
					},
				},
			];
		}, [] as Leg[]);
		setMapLegs(t);
	}, [legsResource]);

	useEffect(() => {
		if (!legsResource) return;
		setFirstLeg(legsResource[0]);
		if (legsResource.length > 1) {
			setReturnLeg(legsResource[legsResource.length - 1]);
		}
	}, [legsResource]);

	if (isMultiLeg) {
		return (
			<FullRow>
				<Wrapper ref={sliderWrapperRef}>
					<LegsInfoCarousel setCurrentIndex={setCurrentSlideIndex} fixedWidth={sliderWidth}>
						{legsResource.map((leg, i) =>
							isDesktop ? (
								<LegsInfoByType
									smallTicket={smallTicket}
									key={leg.id}
									firstLeg={leg}
									flightTypeName={`Leg ${i + 1}`}
									mapLegs={mapLegs[i] ? [mapLegs[i]] : []}
									inverted={inverted}
									isUpcomingTrip={isUpcomingTrip}
									businessCase={businessCase}
									numberOfSeats={numberOfSeats}
									aircraftName={aircraftName}
									isInShade={currentSlideIndex !== i}
									isMultiLeg
									fixedWidth={sliderWidth}
									onEditRequestClick={onEditRequestClick}
								/>
							) : (
								<TripInfoCardMobile
									key={leg.id}
									businessCase={businessCase}
									firstLeg={leg}
									inverted
									withDuration={!!(businessCase as BusinessCase).trip}
									onEditRequestClick={onEditRequestClick}
									detailed={smallTicket}
									aircraftName={aircraftName}
									isUpcomingTrip={isUpcomingTrip}
								/>
							),
						)}
					</LegsInfoCarousel>
				</Wrapper>
			</FullRow>
		);
	}

	return (
		<FullRow>
			<Wrapper>
				{isDesktop ? (
					<LegsInfoByType
						smallTicket={smallTicket}
						firstLeg={firstLeg}
						returnLeg={returnLeg}
						flightTypeName={flightTypeName}
						mapLegs={mapLegs[0] ? [mapLegs[0]] : []}
						inverted={inverted}
						isUpcomingTrip={isUpcomingTrip}
						numberOfSeats={numberOfSeats}
						aircraftName={aircraftName}
						businessCase={businessCase}
						isOneWay={businessCase?.inquiry?.flightType?.constantKey === 'FLIGHTTYPE_ONE_WAY'}
						isRoundTrip={businessCase?.inquiry?.flightType?.constantKey === 'FLIGHTTYPE_ROUND_TRIP'}
						onEditRequestClick={onEditRequestClick}
					/>
				) : (
					<TripInfoCardMobile
						businessCase={businessCase}
						firstLeg={firstLeg}
						returnLeg={returnLeg}
						inverted={!inverted}
						isUpcomingTrip={isUpcomingTrip}
						withDuration={!!(businessCase as BusinessCase).trip}
						onEditRequestClick={onEditRequestClick}
						detailed={smallTicket}
						aircraftName={aircraftName}
						showMorePlaceInfo
					/>
				)}
			</Wrapper>
		</FullRow>
	);
};

export default LegsInfo;
