import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Text } from '../components/Text';

export const Row = styled(Box)<{ separatedOnMobile?: boolean }>`
	position: relative;
	display: flex;
	align-items: flex-start;
	margin: 0;
	flex-direction: column;
	${({ theme }) => theme.breakpoints.up('md')} {
		flex-direction: row;
	}

	${({ theme }) => theme.breakpoints.down('md')} {
		${({ separatedOnMobile }) =>
			separatedOnMobile &&
			`
			background: rgba(21, 101, 192, 0.08);
			border-radius: 8px;
			padding: 0 8px;
			& + & {
				margin-top: 16px;
			}
		`};
	}
`;

export const Col = styled('div')<{
	size?: number;
}>`
	margin-top: 1rem;
	padding: 0;
	width: 100%;
	${({ theme }) => theme.breakpoints.up('md')} {
		width: ${({ size }) => (size ? `${(100 / 12) * size}%` : '100%')};
		& + & {
			padding-left: 30px;
		}
	}
`;

export const TitleInputContainer = styled('div')`
	padding-left: 10px;
	margin: 1rem 0 1rem;
	font-weight: 500;
	font-size: 12px;
	line-height: 160%;
	display: flex;
	align-items: center;
`;

export const MandatoryField = styled(
	styled(Box)({
		color: 'red',
		marginLeft: '5px',
		display: 'inline-block',
	}),
)`
	&::after {
		content: '*';
	}
`;

export const InputContainer = styled('div')`
	margin: 20px 0;
	position: relative;
`;

export const TitleUploadButtonContainer = styled(TitleInputContainer)`
	margin: 3rem 0 0 1rem;
`;

export const ErrorMessage = styled(Text)`
	display: block;
	margin: 6px 0 0 10px;
`;

export const TextInputSuffix = styled('span')`
	font-size: 14px;
	font-weight: 400;
	line-height: 23px;
	color: #334057;
`;
