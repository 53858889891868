import { FC, useLayoutEffect } from 'react';

import { useGoogleMap } from '@react-google-maps/api';

import { Point } from './types';

interface BoundsProps {
	points: Point[];
}

declare const google: any;

export const Bounds: FC<BoundsProps> = ({ points }) => {
	const map = useGoogleMap();

	useLayoutEffect(() => {
		if (points.length === 0) {
			return;
		}

		if (points.length === 1) {
			map.setCenter(new google.maps.LatLng(points[0]));
		} else {
			const bounds = new google.maps.LatLngBounds();

			points.forEach((point) => {
				bounds.extend(new google.maps.LatLng(point));
			});

			map.fitBounds(bounds, 20);
		}
	}, [map, points]);

	return null;
};
