import { FC, useMemo } from 'react';

import { CircularProgress } from '@mui/material';
import { GoogleMap, useLoadScript } from '@react-google-maps/api';

import { getContextForApp } from '../../../utils/config';
import { Bounds } from './Bounds';
import { Line } from './Line';
import { Marker } from './Marker';
import { options } from './options';
import { Leg, Point } from './types';

const containerStyle = {
	width: '100%',
	height: '100%',
	minHeight: '277px',
};

const center = {
	lat: 39.5,
	lng: -98.35,
};

interface MapComponentProps {
	legs: Leg[];
}

const getPointKey = (point: Point) => `${point.lat},${point.lng}`;

const getLegKey = (leg: Leg) => `${getPointKey(leg.from)};${getPointKey(leg.to)}`;

const getPointsFromLegs = (legs: Leg[]): Point[] =>
	legs.reduce((acc, leg) => (leg.from && leg.to ? [...acc, leg.from, leg.to] : acc), []);

export const MapComponent: FC<MapComponentProps> = ({ legs }) => {
	const { googleMapsApiKey } = getContextForApp();
	const { isLoaded, loadError } = useLoadScript({
		googleMapsApiKey,
	});
	const points = useMemo(() => getPointsFromLegs(legs), [legs]);

	const renderMap = () => (
		<GoogleMap mapContainerStyle={containerStyle} center={center} zoom={3.8} options={options}>
			{/* Child components, such as markers, info windows, etc. */}
			<Bounds points={points} />

			{points.map((point, index) => (
				<Marker key={`${getPointKey(point)}_${index}`} point={point} />
			))}

			{legs.map(
				(leg) => leg.from && leg.to && <Line key={getLegKey(leg)} points={[leg.from, leg.to]} />,
			)}
		</GoogleMap>
	);

	if (loadError) {
		return <div>Map cannot be loaded right now, sorry.</div>;
	}

	return isLoaded ? renderMap() : <CircularProgress />;
};
