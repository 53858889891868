import { FC } from 'react';

import styled from '@emotion/styled';
import {
	ConnectingAirportsOutlined,
	EventOutlined,
	FlightOutlined,
	LocationOnOutlined,
	PersonOutlineOutlined,
} from '@mui/icons-material';
import { Box, Skeleton, Stack, useMediaQuery } from '@mui/material';

import { formatDate, formatDateWithTz } from '@fllite-fe/shared/src/utils/datetime';

import { muiTheme } from '../../muiTheme';
import { InquiryLeg } from '../../types';
import { capitalizeWords } from '../../utils/fp';
import { MapComponent } from '../RequestAFlight/Map';
import { Leg } from '../RequestAFlight/Map/types';
import Ticket from '../Ticket';
import TicketASide from '../Ticket/TicketASide';
import TicketContent from '../Ticket/TicketContent';

const ContentWrapper = styled('div')`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

const MobileContentWrapper = styled(ContentWrapper)`
	flex-direction: column;
`;

const ContentCol = styled('div')`
	width: 40%;
`;
const ContentColIcon = styled('div')`
	margin-top: 32px;
`;

const RowWithIcon = styled('div')`
	display: flex;
	flex-direction: row;
	margin-bottom: 0px;
`;
const RowWithIconCol = styled('div')`
	display: flex;

	flex-direction: column;
`;
const RowWithIconColIcon = styled('div')`
	margin-right: 12px;
	display: flex;
	align-items: center;
`;
const RowWithIconColPinIcon = styled(RowWithIconColIcon)`
	margin-top: 12px;
	display: block;
`;
const Title = styled('h2')`
	margin-bottom: 0;
	font-weight: 600;
	font-size: 16px;
	text-transform: uppercase;
	${({ theme }) => theme.breakpoints.up('md')} {
		font-size: 18px;
	}
`;

const AirportCode = styled('h3')`
	font-weight: 400;
	font-size: 24px;
	letter-spacing: 1px;
	margin-bottom: 0;
	margin-top: 16px;
	${({ theme }) => theme.breakpoints.up('md')} {
		font-size: 32px;
	}
`;
const AirportName = styled('p')`
	font-weight: 400;
	font-size: 10px;
	margin-bottom: 0;
	margin-top: 0;
	${({ theme }) => theme.breakpoints.up('md')} {
		font-size: 14px;
	}
`;
const AirportLocation = styled('p')`
	font-weight: 400;
	font-size: 10px;
	margin-bottom: 0;
	margin-top: 0;
	${({ theme }) => theme.breakpoints.up('md')} {
		font-size: 14px;
	}
`;
const DateTitle = styled('h4')`
	font-weight: 800;
	font-size: 12px;
	letter-spacing: 2px;
	text-transform: uppercase;
	color: #53ae0e;
	margin-bottom: 0;
`;
const DateRow = styled('p')`
	font-size: 16px;
	margin-bottom: 8px;
	margin-top: 4px;
`;
const DateRowDay = styled('span')`
	font-weight: 600;
`;
const DateRowTime = styled('span')`
	font-weight: 400;
`;
const AirplaneType = styled('p')`
	font-weight: 600;
	font-size: 16px;
	margin-top: 8px;
	margin-bottom: 8px;
`;
const PassengersWrapper = styled('p')`
	margin-top: 8px;
	margin-bottom: 8px;
`;
const PassengersCount = styled('strong')`
	font-weight: 600;
`;

const FlightType = styled('p')`
	background: linear-gradient(93.22deg, #53ae0e 5.2%, #5bd200 96.46%);
	border-radius: 14px;
	position: absolute;
	top: 20px;
	right: 20px;
	text-transform: uppercase;
	padding: 4px 12px;
	align-items: center;
	font-size: 10px;
	font-weight: 700;
	letter-spacing: 1px;
`;

interface LegsInfoCardProps {
	mapLegs: Leg[];
	firstLeg: InquiryLeg;
	returnLeg?: InquiryLeg;
	inverted?: boolean;
	flightTypeName: string;
	aircraftName: string;
	numberOfSeats: number;
	isInShade?: boolean;
	isMultiLeg?: boolean;
	fixedWidth?: number;
}

export const LegsInfoCard: FC<LegsInfoCardProps> = ({
	flightTypeName,
	mapLegs,
	firstLeg,
	returnLeg,
	inverted,
	aircraftName,
	numberOfSeats,
	isInShade,
	isMultiLeg,
	fixedWidth,
}) => {
	const isDesktop = useMediaQuery(muiTheme.breakpoints.up('sm'));

	if (!firstLeg?.departureAirport.name) {
		return (
			<Ticket
				inverted={inverted}
				isInShade={isInShade}
				isMultiLeg={isMultiLeg}
				fixedWidth={fixedWidth}
			>
				<TicketASide>
					<Skeleton variant="rectangular" height={260} width="100%" />
				</TicketASide>
				<TicketContent>
					<Skeleton variant="rectangular" height={260} width="100%" />
				</TicketContent>
			</Ticket>
		);
	}

	return (
		<Ticket
			inverted={inverted}
			isInShade={isInShade}
			isMultiLeg={isMultiLeg}
			fixedWidth={fixedWidth}
		>
			<TicketASide>
				<MapComponent legs={mapLegs} />
			</TicketASide>
			<TicketContent>
				<Title style={{ color: inverted ? '#353535' : 'inherit' }}>Itinerary</Title>

				<FlightType>{flightTypeName}</FlightType>
				<ContentWrapper>
					<ContentCol>
						<Stack justifyContent="space-between" height="100%">
							<Box>
								<RowWithIcon>
									<RowWithIconColPinIcon>
										<LocationOnOutlined
											color="success"
											fontSize="small"
											sx={{ marginTop: '16px' }}
										/>
									</RowWithIconColPinIcon>
									<RowWithIconCol>
										<AirportCode style={{ color: inverted ? '#353535' : 'inherit' }}>
											{firstLeg?.departureAirport.airportCode}
										</AirportCode>
										<AirportName style={{ color: inverted ? '#353535' : 'inherit' }}>
											{capitalizeWords(firstLeg?.departureAirport.name)}
										</AirportName>
										<AirportLocation style={{ color: inverted ? '#353535' : 'inherit' }}>
											{capitalizeWords(firstLeg?.departureAirport.city)},{' '}
											{firstLeg?.departureAirport.state?.code}
										</AirportLocation>
									</RowWithIconCol>
								</RowWithIcon>
							</Box>
							{isDesktop && (
								<DepartureBox aircraftName={aircraftName} firstLeg={firstLeg} inverted={inverted} />
							)}
						</Stack>
					</ContentCol>
					<ContentColIcon>
						{returnLeg ? (
							<ConnectingAirportsOutlined
								sx={{ color: inverted ? '#353535' : 'white' }}
								fontSize="large"
							/>
						) : (
							<FlightOutlined
								sx={{ color: inverted ? '#353535' : 'white', transform: 'rotate(90deg)' }}
							/>
						)}
					</ContentColIcon>
					<ContentCol>
						<Stack justifyContent="space-between" height="100%">
							<Box>
								<AirportCode style={{ color: inverted ? '#353535' : 'inherit' }}>
									{firstLeg?.destinationAirport.airportCode}
								</AirportCode>
								<AirportName style={{ color: inverted ? '#353535' : 'inherit' }}>
									{capitalizeWords(firstLeg?.destinationAirport.name)}
								</AirportName>
								<AirportLocation style={{ color: inverted ? '#353535' : 'inherit' }}>
									{capitalizeWords(firstLeg?.destinationAirport.city)},{' '}
									{firstLeg?.destinationAirport.state?.code}
								</AirportLocation>
							</Box>
							{isDesktop && (
								<Box>
									{returnLeg && <ReturnBox returnLeg={returnLeg} inverted={inverted} />}
									<Passengers numberOfSeats={numberOfSeats} inverted={inverted} />
								</Box>
							)}
						</Stack>
					</ContentCol>
				</ContentWrapper>
				{!isDesktop && (
					<MobileContentWrapper>
						<DepartureBox aircraftName={aircraftName} firstLeg={firstLeg} inverted={inverted} />
						{returnLeg && <ReturnBox returnLeg={returnLeg} inverted={inverted} />}
						<Passengers numberOfSeats={numberOfSeats} inverted={inverted} />
					</MobileContentWrapper>
				)}
			</TicketContent>
		</Ticket>
	);
};

interface DepartureBoxProps {
	firstLeg: InquiryLeg;
	inverted?: boolean;
	aircraftName: string;
}

const DepartureBox: FC<DepartureBoxProps> = ({ firstLeg, inverted, aircraftName }) => (
	<Box>
		<DateTitle>Departure</DateTitle>
		<RowWithIcon>
			<RowWithIconColIcon>
				<EventOutlined color="success" fontSize="small" />
			</RowWithIconColIcon>
			<RowWithIconCol>
				<DateRow>
					<DateRowDay
						data-testid="departure-date"
						style={{ color: inverted ? '#353535' : 'inherit' }}
					>
						{formatDate(firstLeg?.departureAt, 'MMM DD, YYYY')}
					</DateRowDay>{' '}
					<DateRowTime
						data-testid="departure-time"
						style={{ color: inverted ? '#353535' : 'inherit' }}
					>
						{formatDateWithTz(
							firstLeg?.departureAt,
							firstLeg?.departureAirport.timeZone,
							'hh:mm A z',
						)}{' '}
					</DateRowTime>
				</DateRow>
			</RowWithIconCol>
		</RowWithIcon>
		<RowWithIcon>
			<RowWithIconColIcon>
				<FlightOutlined color="success" fontSize="small" />
			</RowWithIconColIcon>
			<RowWithIconCol>
				<AirplaneType style={{ color: inverted ? '#353535' : 'inherit' }}>
					{aircraftName}
				</AirplaneType>
			</RowWithIconCol>
		</RowWithIcon>
	</Box>
);

interface ReturnBoxProps {
	returnLeg: InquiryLeg;
	inverted?: boolean;
}

const ReturnBox: FC<ReturnBoxProps> = ({ returnLeg, inverted }) => (
	<>
		<DateTitle>Return</DateTitle>
		<RowWithIcon>
			<RowWithIconColIcon>
				<EventOutlined color="success" fontSize="small" />
			</RowWithIconColIcon>
			<RowWithIconCol>
				<DateRow>
					<DateRowDay style={{ color: inverted ? '#353535' : 'inherit' }}>
						{formatDate(returnLeg?.departureAt, 'MMM DD, YYYY')}
					</DateRowDay>{' '}
					<DateRowTime style={{ color: inverted ? '#353535' : 'inherit' }}>
						{formatDateWithTz(
							returnLeg?.departureAt,
							returnLeg?.departureAirport.timeZone,
							'hh:mm A z',
						)}{' '}
					</DateRowTime>
				</DateRow>
			</RowWithIconCol>
		</RowWithIcon>
	</>
);

interface PassengersProps {
	numberOfSeats: number;
	inverted?: boolean;
}

const Passengers: FC<PassengersProps> = ({ numberOfSeats, inverted }) => (
	<RowWithIcon>
		<RowWithIconColIcon>
			<PersonOutlineOutlined color="success" fontSize="small" />
		</RowWithIconColIcon>
		<RowWithIconCol>
			<PassengersWrapper style={{ color: inverted ? '#353535' : 'inherit' }}>
				<PassengersCount>{numberOfSeats}</PassengersCount> seat{numberOfSeats > 1 && 's'}
			</PassengersWrapper>
		</RowWithIconCol>
	</RowWithIcon>
);
