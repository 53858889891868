import { FC, useMemo } from 'react';

import { PolylineF } from '@react-google-maps/api';

import { Point } from './types';

const lineSymbol = {
	path: 'M 0,-1 0,1',
	strokeOpacity: 1,
	scale: 2,
	strokeColor: '#ffffff',
};

declare const google: any;
interface LineProps {
	points: [Point, Point];
}

export const Line: FC<LineProps> = ({ points }) => {
	const planeSymbol = useMemo(
		() => ({
			path: 'M46.5946 20.3752L39.3047 25.1857L20.0521 15.6025C18.2936 14.8443 16.1545 14.8434 14.4636 15.7425L10.7847 17.6986L29.0036 30.6628L16.5418 39.1765L8.90397 31.9119L6.74326 34.9484L13.3008 43.7312L16.9151 54.0789L20.6408 53.9856L18.8892 43.5912L32.9159 38.0207L33.4758 60.3744L37.8906 58.0271C39.5814 57.128 40.0419 55.7468 40.3958 53.8636L43.2169 32.5436L51.294 29.2135C53.9639 27.9902 55.5342 24.9901 54.6588 22.1863C54.615 22.0472 54.5688 21.9283 54.5172 21.8312C54.4655 21.7341 54.3928 21.6293 54.3027 21.5167C52.467 19.2215 49.1017 18.8458 46.5946 20.3752Z',
			fillOpacity: 1,
			fillColor: '#ffffff',
			anchor: new google.maps.Point(33, 33),
			strokeOpacity: 0,
			scale: 0.8,
			rotation: -62,
		}),
		[google],
	);

	return (
		<PolylineF
			path={points}
			options={{
				strokeOpacity: 0,
				clickable: false,
				draggable: false,
				editable: false,
				icons: [
					{
						icon: lineSymbol,
						offset: '0',
						repeat: '10px',
					},
					{
						icon: planeSymbol,
						offset: '55%',
					},
				],
			}}
		/>
	);
};
