import { FC, ReactNode } from 'react';

import styled from '@emotion/styled';

const Wrapper = styled('div')`
	padding: 5px 12px;
	position: relative;
	${({ theme }) => theme.breakpoints.up('sm')} {
		padding-left: 18px;
		padding-right: 5px;
		width: calc(70% - 2 * 5px);
	}
`;

interface TicketContentProps {
	children: ReactNode;
}

const TicketContent: FC<TicketContentProps> = ({ children }) => <Wrapper>{children}</Wrapper>;

export default TicketContent;
