// eslint-disable-next-line no-undef

const TYPE_LABELS_ICON = 'labels.icon';
const TYPE_GEOMETRY_FILL = 'geometry.fill';

export const options: google.maps.MapOptions = {
	draggable: false,
	draggableCursor: 'default',
	draggingCursor: 'default',
	styles: [
		{
			featureType: 'all',
			elementType: 'labels',
			stylers: [
				{
					visibility: 'simplified',
				},
				{
					invert_lightness: true,
				},
			],
		},
		{
			featureType: 'all',
			elementType: 'labels.text',
			stylers: [
				{
					color: '#004ba6',
				},
				{
					lightness: 50,
				},
			],
		},
		{
			featureType: 'all',
			elementType: 'labels.text.stroke',
			stylers: [
				{
					invert_lightness: true,
				},
			],
		},
		{
			featureType: 'all',
			elementType: TYPE_LABELS_ICON,
			stylers: [
				{
					visibility: 'off',
				},
			],
		},
		{
			featureType: 'administrative',
			elementType: TYPE_GEOMETRY_FILL,
			stylers: [
				{
					color: '#0056be',
				},
			],
		},
		{
			featureType: 'administrative',
			elementType: 'geometry.stroke',
			stylers: [
				{
					color: '#000000',
				},
				{
					lightness: 17,
				},
				{
					weight: 1.2,
				},
				{
					visibility: 'off',
				},
			],
		},
		{
			featureType: 'administrative.neighborhood',
			elementType: 'all',
			stylers: [
				{
					visibility: 'on',
				},
			],
		},
		{
			featureType: 'landscape',
			elementType: 'all',
			stylers: [
				{
					color: '#0056be',
				},
			],
		},
		{
			featureType: 'landscape',
			elementType: 'geometry',
			stylers: [
				{
					color: '#004ba6',
				},
				{
					lightness: -20,
				},
			],
		},
		{
			featureType: 'landscape.man_made',
			elementType: 'all',
			stylers: [
				{
					visibility: 'on',
				},
			],
		},
		{
			featureType: 'landscape.natural',
			elementType: 'all',
			stylers: [
				{
					visibility: 'on',
				},
			],
		},
		{
			featureType: 'poi',
			elementType: 'all',
			stylers: [
				{
					visibility: 'on',
				},
			],
		},
		{
			featureType: 'poi',
			elementType: 'geometry',
			stylers: [
				{
					color: '#0056be',
				},
				{
					visibility: 'on',
				},
			],
		},
		{
			featureType: 'poi',
			elementType: TYPE_GEOMETRY_FILL,
			stylers: [
				{
					visibility: 'on',
				},
				{
					hue: '#0073ff',
				},
				{
					lightness: -50,
				},
			],
		},
		{
			featureType: 'poi',
			elementType: TYPE_LABELS_ICON,
			stylers: [
				{
					visibility: 'off',
				},
				{
					hue: '#0073ff',
				},
			],
		},
		{
			featureType: 'poi.business',
			elementType: TYPE_GEOMETRY_FILL,
			stylers: [
				{
					lightness: 40,
				},
			],
		},
		{
			featureType: 'road',
			elementType: TYPE_GEOMETRY_FILL,
			stylers: [
				{
					color: '#0056be',
				},
				{
					visibility: 'on',
				},
			],
		},
		{
			featureType: 'road.highway',
			elementType: 'geometry',
			stylers: [
				{
					visibility: 'simplified',
				},
			],
		},
		{
			featureType: 'road.arterial',
			elementType: 'geometry',
			stylers: [
				{
					lightness: 18,
				},
				{
					visibility: 'simplified',
				},
				{
					weight: 0.5,
				},
			],
		},
		{
			featureType: 'road.local',
			elementType: 'geometry',
			stylers: [
				{
					color: '#0056be',
				},
				{
					lightness: -10,
				},
				{
					visibility: 'on',
				},
				{
					weight: 1.0,
				},
			],
		},
		{
			featureType: 'transit',
			elementType: 'all',
			stylers: [
				{
					visibility: 'on',
				},
			],
		},
		{
			featureType: 'transit',
			elementType: 'geometry',
			stylers: [
				{
					lightness: 0,
				},
				{
					color: '#0056be',
				},
				{
					gamma: 0.5,
				},
				{
					visibility: 'on',
				},
			],
		},
		{
			featureType: 'transit.station',
			elementType: TYPE_LABELS_ICON,
			stylers: [
				{
					visibility: 'on',
				},
				{
					hue: '#0073ff',
				},
			],
		},
		{
			featureType: 'water',
			elementType: 'geometry',
			stylers: [
				{
					color: '#0056be',
				},
				{
					gamma: 1.0,
				},
				{
					lightness: 0,
				},
			],
		},
	],
	disableDefaultUI: true,
};
