import { scroller } from 'react-scroll';

export const scrollToTarget = (target: string) => {
	scroller.scrollTo(target, {
		duration: 800,
		delay: 50,
		smooth: true,
		offset: -150, // save 150px for the navbar
	});
};
