import { FC } from 'react';

import styled from '@emotion/styled';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import { Box, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useRouter } from 'next/router';

import { Icon } from '@fllite-fe/shared/src/components/Icon';
import { diff, formatDateWithTz } from '@fllite-fe/shared/src/utils/datetime';
import { capitalizeWords } from '@fllite-fe/shared/src/utils/fp';

import { BusinessCase, InquiryLeg } from '../../types';
import { getBCStatus } from '../../utils/getBCStatus';
import { isTripHighlighted } from '../../utils/isTripHighlighted';
import { CardAction, CardContainer } from '../LegsInfo/LegsInfoCardSimple';
import { SeparatorThicker } from '../SeparatorThicker';
import { TripInfoCardItem } from './TripInfoCardItem';
import { TripInfoCardMobileDetailedPart } from './TripInfoCardMobileDetailedPart';

const SeparatorContainer = styled('div')`
	height: auto;
	display: flex;
	flex-direction: row;
	justify-content: center;
	position: relative;
	margin-top: 8px;

	${({ theme }) => theme.breakpoints.up('md')} {
		height: 240px;
		margin-top: 0px;
		flex-direction: column;
	}
`;

const StyledSeparatorThicker = styled(SeparatorThicker)`
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	position: absolute;
	top: 50%;
	left: 0;
	z-index: 0;
`;

const TripInfoText = styled('div')`
	z-index: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 14px;
	padding: 4px 12px;
	height: 24px;
	background: ${({ theme }) => theme.gradients.green};
	color: white;
	font-weight: 600;
	text-transform: uppercase;
	font-family: poppins, Roboto, 'Helvetica Neue';
	font-size: 12px;
`;

const CardActionMobile = styled(CardAction)<{ isBlue?: boolean }>`
	background: ${({ isBlue }) =>
		isBlue
			? 'radial-gradient(circle at 100% 121px, transparent 8px, #2077e0 9px, #2077e0), radial-gradient(circle at 0 121px, transparent 8px, #2077e0 8px, #2077e0)'
			: 'radial-gradient(circle at 100% 121px, rgba(204, 0, 0, 0) 10px, #fff 9px),radial-gradient(circle at 0 121px, rgba(204, 0, 0, 0) 10px, #fff 9px)'};
`;

const StyledIcon = styled(Icon)`
	height: 25px;
	margin-bottom: 5px;
	display: inline-flex;
`;

const StyledSeparator = styled(StyledSeparatorThicker)`
	width: 100%;
`;

const getSeatsText = (numberOfSeats: number) => `seat${numberOfSeats > 1 ? 's' : ''}`;
interface Props {
	businessCase?: BusinessCase;
	firstLeg: InquiryLeg;
	returnLeg?: InquiryLeg;
	detailed?: boolean;
	withDuration?: boolean;
	inverted?: boolean;
	showPrice?: boolean;
	onEditRequestClick?: () => void;
	aircraftName?: string;
	numberOfSeats?: number;
	isRoundTrip?: boolean;
	isMultiLeg?: boolean;
	isOneWay?: boolean;
	isUpcomingTrip?: boolean;
	showMorePlaceInfo?: boolean;
	requestedNumberOfSeats?: number;
}

export const TripInfoCardMobile: FC<Props> = ({
	businessCase,
	firstLeg,
	returnLeg,
	detailed,
	withDuration,
	inverted,
	showPrice,
	onEditRequestClick,
	aircraftName,
	numberOfSeats: numberOfSeatsFromProps,
	isRoundTrip: isRoundTripFromProps,
	isMultiLeg: isMultiLegFromProps,
	isUpcomingTrip,
	isOneWay: isOneWayFromProps,
	showMorePlaceInfo,
	requestedNumberOfSeats,
}) => {
	const { push } = useRouter();

	if (!firstLeg) {
		return null;
	}

	const {
		numberOfSeats: numberOfSeatsFromBc,
		isRoundTrip: isRoundTripFromBc,
		isMultiLeg: isMultiLegFromBc,
		isOneWay: isOneWayFromBc,
	} = businessCase
		? getBCStatus(businessCase)
		: {
				numberOfSeats: undefined,
				isRoundTrip: undefined,
				isMultiLeg: undefined,
				isOneWay: undefined,
			};

	const isRoundTrip = isRoundTripFromProps || isRoundTripFromBc;
	const isMultiLeg = isMultiLegFromProps || isMultiLegFromBc;
	const isOneWay = isOneWayFromProps || isOneWayFromBc;
	const numberOfSeats = numberOfSeatsFromProps || numberOfSeatsFromBc;

	const proposal = (businessCase as BusinessCase)?.trip?.proposal;

	const depAirport = firstLeg.departureAirport;
	const destAirport = firstLeg.destinationAirport;

	const dateFrom = firstLeg.departureAt;
	const dateFromTimezone = firstLeg.departureAirport?.timeZone;

	const lastProposalLeg = returnLeg;

	const dateTo = lastProposalLeg?.departureAt;
	const dateToTimezone = lastProposalLeg?.departureAirport?.timeZone;

	const isBlue = !inverted;

	const inDays = diff(
		dayjs(firstLeg?.departureAt).startOf('day').format('YYYY-MM-DDTHH:mm'),
		dayjs(new Date()).startOf('day').format('YYYY-MM-DDTHH:mm'),
		'days',
	);

	const hasBookedDesign = isTripHighlighted(businessCase);

	return (
		<CardContainer>
			<CardActionMobile
				isBlue={isBlue}
				onClick={
					isUpcomingTrip
						? () => push(`/detail/${businessCase.id}`)
						: onEditRequestClick
							? onEditRequestClick
							: undefined
				}
				sx={isUpcomingTrip || onEditRequestClick ? {} : { cursor: 'unset' }}
			>
				<Stack width="100%">
					<Stack
						width="100%"
						direction="row"
						minWidth={{ xs: 'unset', md: '350px' }}
						mb={1}
						justifyContent={{ xs: 'flex-start', sm: 'center' }}
					>
						<Stack
							direction="row"
							alignItems="center"
							spacing={2}
							justifyContent="space-between"
							width="100%"
							maxWidth="500px"
						>
							<Stack width={{ xs: '120px', mini: '160px' }}>
								<Typography fontSize={32}>{depAirport.airportCode}</Typography>
								<Typography fontSize={12}>{capitalizeWords(depAirport.city)}</Typography>
								{showMorePlaceInfo && (
									<Typography fontSize={12}>
										{depAirport.state?.code ?? (depAirport as any).stateCode}
									</Typography>
								)}
							</Stack>

							<Box>
								{isRoundTrip || isMultiLeg ? (
									<StyledIcon
										type={isBlue ? 'aircraft-multiple-white' : 'aircraft-multiple-dark-blue'}
									/>
								) : (
									<StyledIcon type={isBlue ? 'aircraft-white-2' : 'aircraft-dark-blue'} />
								)}
							</Box>
							<Stack width={{ xs: '120px', mini: '160px' }}>
								<Typography fontSize={32}>{destAirport.airportCode}</Typography>
								<Typography fontSize={12}>{capitalizeWords(destAirport.city)}</Typography>
								{showMorePlaceInfo && (
									<Typography fontSize={12}>
										{destAirport.state?.code ?? (destAirport as any).stateCode}
									</Typography>
								)}
							</Stack>
						</Stack>
					</Stack>
					<SeparatorContainer>
						<StyledSeparator dashed />
						<TripInfoText>
							{isOneWay && 'One way'}
							{isRoundTrip && 'Round trip'}
							{isMultiLeg && 'Multi leg'}
						</TripInfoText>
					</SeparatorContainer>

					<Box width="100%" mt={2}>
						<Stack
							direction="column"
							spacing={2}
							alignItems={{ xs: 'flex-start', sm: 'center' }}
							textAlign="left"
							width="100%"
						>
							<Stack
								direction="row"
								spacing={5}
								width="100%"
								justifyContent="space-between"
								maxWidth="500px"
							>
								<TripInfoCardItem
									title="Departure"
									icon={<EventOutlinedIcon color="success" fontSize="small" />}
									text={formatDateWithTz(dateFrom, dateFromTimezone, 'MMM D, YYYY')}
									subText={formatDateWithTz(dateFrom, dateFromTimezone, 'hh:mm a z').toUpperCase()}
									duration={withDuration && proposal && proposal.proposalLegs[0].flightDuration}
									{...(!isOneWay ? { maxWidth: true } : {})}
								/>
								{returnLeg && !isOneWay && (
									<TripInfoCardItem
										title="Return"
										icon={<EventOutlinedIcon color="success" fontSize="small" />}
										text={formatDateWithTz(dateTo, dateToTimezone, 'MMM D, YYYY')}
										subText={formatDateWithTz(dateTo, dateToTimezone, 'hh:mm a z').toUpperCase()}
										duration={
											withDuration &&
											proposal &&
											proposal.proposalLegs[proposal.proposalLegs.length - 1].flightDuration
										}
										maxWidth
									/>
								)}
							</Stack>

							{detailed ? (
								<TripInfoCardMobileDetailedPart
									numberOfSeats={numberOfSeats}
									aircraftName={aircraftName}
									proposal={proposal}
									showPrice={showPrice}
									isBlue={isBlue}
									isUpcomingTrip={isUpcomingTrip}
									businessCase={businessCase}
									inDays={inDays}
									hasBookedDesign={hasBookedDesign}
									onEditRequestClick={onEditRequestClick}
									requestedNumberOfSeats={requestedNumberOfSeats}
								/>
							) : (
								<Stack direction="row" alignItems="center" spacing={1}>
									<Typography
										fontSize={12}
										fontWeight={800}
										color="#8ace57"
										letterSpacing="2px"
										textTransform="uppercase"
										component="span"
									>
										Capacity
									</Typography>
									<Typography fontSize={12} component="span">
										<Typography component="span" fontSize={14} fontWeight={600}>
											{numberOfSeats}
										</Typography>{' '}
										{getSeatsText(numberOfSeats)}
									</Typography>
								</Stack>
							)}
						</Stack>
					</Box>
				</Stack>
			</CardActionMobile>
		</CardContainer>
	);
};
